import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { createInvalidMessageValidator, Part, TemplateVersion } from '@frontoffice/data-access/template';
import { EmailInputFieldPartDetail, replaceArguments } from '../../model/email-input-field-part.detail';
import { EmailInputFieldPartStyle } from '../../model/email-input-field-part.style';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { frontofficeEnvironment } from '@shared/environment';
import { Subscription } from 'rxjs';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'app-email-input-field-part-front',
    templateUrl: './email-input-field-part-front.component.html',
})
export class EmailInputFieldPartFrontComponent implements ComponentFront, OnInit, OnChanges, OnDestroy {
    partDetail: EmailInputFieldPartDetail = null;
    partStyle: EmailInputFieldPartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    parentFormGroup: FormGroup;

    answerControlName: string;

    subscriptions: Subscription = new Subscription();

    constructor(public changeDetectorRef: ChangeDetectorRef) {}

    answerControl(): FormControl {
        return this.parentFormGroup?.get(this.answerControlName) as FormControl;
    }

    ngOnInit(): void {
        this.initForm();
        this.changeDisabled(this.partDetail.enabled);
        replaceArguments(this.partDetail, this.templateVersion.arguments);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['part']?.previousValue?.detail?.answer !== changes['part']?.currentValue?.detail?.answer) {
            this.updateAnswer();
        }

        if (
            changes['part']?.previousValue?.detail?.invalidMessage !== changes['part']?.currentValue?.detail?.invalidMessage ||
            changes['part']?.previousValue?.detail?.required !== changes['part']?.currentValue?.detail?.required ||
            changes['part']?.previousValue?.detail?.requiredMessage !== changes['part']?.currentValue?.detail?.requiredMessage
        ) {
            this.answerControl()?.markAsTouched({ onlySelf: true });
            this.answerControl()?.updateValueAndValidity();
        }

        if (changes['part']?.previousValue?.detail?.enabled !== changes['part']?.currentValue?.detail?.enabled) {
            this.changeDisabled(changes['part']?.currentValue?.detail?.enabled);
        }
        replaceArguments(this.partDetail, this.templateVersion.arguments);
    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl(this.answerControlName);
        this.subscriptions.unsubscribe();
    }

    changeDisabled(enabled: boolean): void {
        if (enabled) {
            this.answerControl()?.enable();
        } else {
            this.answerControl()?.disable();
        }
    }

    updateAnswer(): void {
        if (this.parentFormGroup && this.answerControl()) {
            this.answerControl()?.setValue(this.partDetail.answer);
        }
    }

    initForm(): void {
        this.answerControlName = this.part.instanceIdentifier;
        this.parentFormGroup.addControl(
            this.answerControlName,
            new FormControl(this.partDetail.answer, [Validators.email, createInvalidMessageValidator(() => this.partDetail)])
        );

        if (this.partDetail.required) {
            this.answerControl()?.setValidators([
                Validators.required,
                Validators.email,
                createInvalidMessageValidator(() => this.partDetail),
            ]);
        }

        if (this.partDetail.invalidMessage) {
            this.answerControl()?.markAllAsTouched();
            this.parentFormGroup.updateValueAndValidity();
        }

        this.subscriptions.add(
            this.answerControl()
                ?.valueChanges.pipe(
                    // this really should be != not !== because null & undefined are not equal...
                    filter(value => this.partDetail.answer != value),
                    tap(value => (this.partDetail.answer = value)),
                    debounceTime(frontofficeEnvironment.inputdebounce),
                    tap(value => this.onChangeAnswer())
                )
                .subscribe()
        );
    }

    onChangeAnswer(): void {
        this.clearInvalidMessageError();
        this.executeOnChangeAction();
    }

    clearInvalidMessageError(): void {
        this.partDetail.invalidMessage = null;
        this.answerControl()?.setErrors({ invalidmessage: null });
        this.answerControl()?.updateValueAndValidity();
    }

    executeOnChangeAction(): void {
        const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
            return {
                name: templateArgument.name,
                value: templateArgument.value,
                calculatedValue: templateArgument.calculatedValue,
                subArguments: templateArgument.subArguments,
            };
        });
        const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CHANGE');
        if (!!actionLinks && actionLinks.length > 0) {
            this.executeAction.emit({
                trigger: this.part.id,
                actionLinks,
                arguments: templateActionArguments,
            });
        }
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
