import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { RadarChartPartDetail } from '../../model/radar-chart-part.detail';
import { RadarChartPartStyle } from '../../model/radar-chart-part.style';
import { ChartTitleOptions } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/dto/chart-title-options.interface';
import {
    ChartRadarIndicatorOptions,
    ChartRadarOptions,
} from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/dto/chart-radar-options.interface';
import {
    RadarChartItemStyleOptions,
    RadarChartLineStyleOptions,
    RadarChartSeriesDataOptions,
    RadarChartSeriesOptions,
} from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/dto/radar-chart-series-options.interface';
import { ChartLegendOptions } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/dto/chart-legend-options.interface';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

@Component({
    selector: 'app-radar-chart-part-front',
    templateUrl: './radar-chart-part-front.component.html',
    styleUrls: ['./radar-chart-part-front.component.scss'],
})
export class RadarChartPartFrontComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: RadarChartPartDetail;
    partStyle: RadarChartPartStyle;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    parentFormGroup: FormGroup;

    get part(): Part {
        return this._part;
    }

    set part(value: Part) {
        this._part = value;
        this._handleDetailChanged();
    }

    _part: Part;

    options: any;

    chartInstance: any;

    constructor(public changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.chartInstance) {
            this.chartInstance.resize();
        }
    }

    onChartInit(e: any): void {
        this.chartInstance = e;
        this.chartInstance.resize();
    }

    private _handleDetailChanged(): void {
        if (!!this._part) {
            const partDetail = this._part.detail as RadarChartPartDetail;
            const partStyle = this._part.style as RadarChartPartStyle;

            const titleOptions: ChartTitleOptions = {
                text: partDetail?.title,
            };

            const { indicators: indicatorInfo } = partDetail;
            const radar = new ChartRadarOptions({
                indicator:
                    indicatorInfo?.values?.split(';').map(value => {
                        const valueMaxMin = value.split(':');
                        return new ChartRadarIndicatorOptions({
                            name: valueMaxMin[0].replace(' ', '\n'),
                            max: valueMaxMin.length >= 1 ? Number(valueMaxMin[1]) : null,
                        });
                    }) ?? [],
                center: partStyle.centerX && partStyle.centerY ? [partStyle.centerX, partStyle.centerY] : ['50%', '50%'],
                radius: partStyle.radius ? partStyle.radius : '80%',
                shape: partStyle.shape ? partStyle.shape : 'polygon',
            });

            const mapping =
                partDetail?.datasources?.map(sourceDetail => {
                    const sourceStyle = partStyle?.series.find(x => x.id === sourceDetail.id);
                    return {
                        detail: sourceDetail,
                        style: sourceStyle,
                    };
                }) ?? [];

            const series =
                new RadarChartSeriesOptions({
                    data: mapping.map(ref => {
                        const { detail, style } = ref;
                        return new RadarChartSeriesDataOptions({
                            name: detail.name,
                            value: detail?.points?.split(';') ?? [],
                            lineStyle: new RadarChartLineStyleOptions({ color: style?.itemStyle?.color }),
                            itemStyle: new RadarChartItemStyleOptions({ color: style?.itemStyle?.color }),
                            areaStyle: style?.itemStyle?.fillArea ? { color: style?.itemStyle?.areaColor } : null,
                        });
                    }),
                }) ?? [];

            const { legend: legendInfo } = partStyle;
            const legend = new ChartLegendOptions({
                show: legendInfo?.show,
                orient: legendInfo?.orient,
                left: legendInfo?.left,
                top: legendInfo?.top,
                data: mapping.map(source => ({ name: source.detail.name })) ?? [],
                align: 'right',
            });

            this.options = {
                ...this.options,
                title: titleOptions,
                legend,
                radar,
                series,
                tooltip: {
                    trigger: 'item',
                },
            };
        }
    }
}
