import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';

export interface PasswordInputFieldPartDetail extends PartDetail {
    code: string;
    label: string;
    processedLabel: string;

    required: boolean;
    requiredMessage: string;
    processedRequiredMessage: string;

    help: boolean;
    helpInformation: string;
    processedHelpInformation: string;

    answer: string;

    validatePasswordStrength: boolean;
    validatePasswordStrengthMessage: string;
    processedValidatePasswordStrengthMessage: string;

    enabled: boolean;

    invalidMessage: string | null;

    showPasswordIcon: boolean;
}

export async function replaceArguments(detail: PasswordInputFieldPartDetail, templateArguments: TemplateArgument[]): Promise<void> {
    detail.processedLabel = await replaceArgumentsForString(detail.label, templateArguments);
    detail.processedRequiredMessage = await replaceArgumentsForString(detail.requiredMessage, templateArguments);
    detail.processedHelpInformation = await replaceArgumentsForString(detail.helpInformation, templateArguments);
    detail.processedValidatePasswordStrengthMessage = await replaceArgumentsForString(
        detail.validatePasswordStrengthMessage,
        templateArguments
    );
}
