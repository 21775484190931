<img *ngIf="partDetail.linkType === 'url'" [id]="'id-' + part.id" [src]="partDetail.processedUrl" (click)="executeOnClickAction()" />
<img
    *ngIf="partDetail.linkType === 'media-library' && !partDetail.automaticResizing"
    [id]="'id-' + part.id"
    [src]="host + '/v2/media/file/' + partDetail.mediaId"
    (click)="executeOnClickAction()" />
<img
    *ngIf="partDetail.linkType === 'media-library' && partDetail.automaticResizing"
    [id]="'id-' + part.id"
    (click)="executeOnClickAction()" />
