import { BackofficeEnvironmentDto } from './backoffice-environment.dto';

export const backofficeEnvironment: BackofficeEnvironmentDto = {
    production: false,
    protocol: 'https',
    host: 'bd.co-dex.eu',
    applicationHost: 'dev-app.nocode-x.com',
    backEndHost: 'dev-back.nocode-x.com',
    stripePublic: 'pk_test_51J3oVkELD7yMbEbBTyIHT3gQTTEszEuI3nwwI4616ScMobaNJ3Qb3lkcIjnRMZ5sVltenWv1eg2QxRZwpepBr0c000DYvFKeGb',
    amLink: 'https://login.co-dex.eu/auth',
    accountManagementLink: 'https://login.co-dex.eu/auth/realms/nocodex/account?referrer=nocodex-broker#/personal-info',
    logoutLink:
        'https://login.co-dex.eu/auth/realms/nocodex/protocol/openid-connect/logout?post_logout_redirect_uri=https://dev.nocode-x.com',
    gridbaseddesign: true,
    dialogConfig: {
        normal: {
            width: '50vw',
            maxHeight: '90vh',
        },
        big: {
            width: '75vw',
            maxHeight: '75vh',
        },
        small: {
            width: '30vw',
            maxHeight: '90vh',
        },
        extrasmall: {
            width: '500px',
            maxHeight: '90vh',
        },
    },
    sideNav: [
        {
            name: 'v2.navigation.editor',
            icon: 'build',
            link: 'editor',
            roles: [
                'ROLE_READ_API',
                'ROLE_WRITE_API',
                'ROLE_READ_TEMPLATE',
                'ROLE_WRITE_TEMPLATE',
                'ROLE_READ_DATA',
                'ROLE_WRITE_DATA',
                'ROLE_READ_DATAFORMAT',
                'ROLE_WRITE_DATAFORMAT',
                'ROLE_READ_MEDIA',
                'ROLE_WRITE_MEDIA',
                'ROLE_READ_ACTIONS',
                'ROLE_WRITE_ACTIONS',
            ],
        },
        {
            name: 'v2.navigation.logs.title',
            icon: 'bug_report',
            children: [
                {
                    name: 'v2.navigation.logs.application',
                    link: 'logs/application',
                },
                {
                    name: 'v2.navigation.logs.audit',
                    link: 'logs/audit',
                },
            ],
            link: 'logs',
            roles: ['ROLE_READ_ACTIONS', 'ROLE_WRITE_ACTIONS'],
        },
        {
            name: 'v2.navigation.usermanagement.title',
            icon: 'web',
            children: [
                {
                    name: 'v2.navigation.usermanagement.users',
                    link: 'usermanagement/users',
                },
                {
                    name: 'v2.navigation.usermanagement.groups',
                    link: 'usermanagement/groups',
                },
                {
                    name: 'v2.navigation.usermanagement.roles',
                    link: 'usermanagement/roles',
                },
            ],
            roles: ['USER_ADMIN'],
        },
        {
            name: 'v2.navigation.plugins',
            icon: 'electrical_services',
            link: 'plugins',
        },
        {
            name: 'v2.navigation.tasks',
            icon: 'task',
            link: 'tasks',
            roles: [
                'ROLE_READ_API',
                'ROLE_WRITE_API',
                'ROLE_READ_TEMPLATE',
                'ROLE_WRITE_TEMPLATE',
                'ROLE_READ_DATA',
                'ROLE_WRITE_DATA',
                'ROLE_READ_DATAFORMAT',
                'ROLE_WRITE_DATAFORMAT',
                'ROLE_READ_MEDIA',
                'ROLE_WRITE_MEDIA',
                'ROLE_READ_ACTIONS',
                'ROLE_WRITE_ACTIONS',
            ],
        },
        {
            type: 'divider',
        },
        {
            name: 'v2.navigation.company',
            icon: 'domain',
            link: 'companies/company/{companyId}',
            addCompany: true,
            roles: ['COMPANY_ADMIN'],
        },
        {
            name: 'v2.navigation.company.billing',
            icon: 'credit_card',
            link: 'billing',
            roles: ['BILLING_ADMIN'],
        },
        {
            name: 'v2.navigation.marketplace',
            icon: 'widgets',
            link: 'hub',
        },
        {
            type: 'divider',
            roles: ['ROLE_ADMIN'],
        },
        {
            name: 'navigation.admin',
            icon: 'build',
            children: [
                {
                    name: 'navigation.management',
                    link: 'management/',
                },
            ],
            roles: ['ROLE_ADMIN'],
        },
    ],
    showErrorDialog: true,
    logErrors: true,
    autosavedebounce: 2500,
    inputdebounce: 400,
    recentWorkspaceCookieName: 'RECENT_WORKSPACES_DEV',
    recentSubWorkspaceCookieName: 'RECENT_SUBWORKSPACES_DEV',
    languages: [
        {
            languageCode: 'am',
            languageName: 'አማርኛ',
            englishLanguageName: 'Amharic',
        },
        {
            languageCode: 'ay',
            languageName: 'Aymara',
            englishLanguageName: 'Aymara',
        },
        {
            languageCode: 'ar',
            languageName: 'العربية',
            englishLanguageName: 'Arabic',
        },
        {
            languageCode: 'arn',
            languageName: 'Mapudungun',
            englishLanguageName: 'Mapudungun',
        },
        {
            languageCode: 'bn',
            languageName: 'বাংলা',
            englishLanguageName: 'Bengali',
        },
        {
            languageCode: 'bg',
            languageName: 'български',
            englishLanguageName: 'Bulgarian',
        },
        {
            languageCode: 'my',
            languageName: 'မြန်မာစာ',
            englishLanguageName: 'Burmese',
        },
        {
            languageCode: 'bs',
            languageName: 'Bosanski',
            englishLanguageName: 'Bosnian',
        },
        {
            languageCode: 'ca',
            languageName: 'Català',
            englishLanguageName: 'Catalan',
        },
        {
            languageCode: 'cs',
            languageName: 'Čeština',
            englishLanguageName: 'Czech',
        },
        {
            languageCode: 'da',
            languageName: 'Dansk',
            englishLanguageName: 'Danish',
        },
        {
            languageCode: 'de',
            languageName: 'Deutsch',
            englishLanguageName: 'German',
        },
        {
            languageCode: 'et',
            languageName: 'Eesti',
            englishLanguageName: 'Estonian',
        },
        {
            languageCode: 'en',
            languageName: 'English',
            englishLanguageName: 'English',
        },
        {
            languageCode: 'fi',
            languageName: 'Suomi',
            englishLanguageName: 'Finnish',
        },
        {
            languageCode: 'fr',
            languageName: 'Français',
            englishLanguageName: 'French',
        },
        {
            languageCode: 'el',
            languageName: 'Ελληνικά',
            englishLanguageName: 'Greek',
        },
        {
            languageCode: 'gn',
            languageName: 'Aña',
            englishLanguageName: 'Guarani',
        },
        {
            languageCode: 'gu',
            languageName: 'ગુજરાતી',
            englishLanguageName: 'Gujarati',
        },
        {
            languageCode: 'guc',
            languageName: 'Wayuunaiki',
            englishLanguageName: 'Wayuu',
        },
        {
            languageCode: 'ha',
            languageName: 'Hausa',
            englishLanguageName: 'Hausa',
        },
        {
            languageCode: 'hi',
            languageName: 'हिन्दी',
            englishLanguageName: 'Hindi',
        },
        {
            languageCode: 'hns',
            languageName: 'Hunsrik',
            englishLanguageName: 'Hunsrik',
        },
        {
            languageCode: 'hr',
            languageName: 'Hrvatski',
            englishLanguageName: 'Croatian',
        },
        {
            languageCode: 'hu',
            languageName: 'Magyar',
            englishLanguageName: 'Hungarian',
        },
        {
            languageCode: 'id',
            languageName: 'Bahasa Indonesia',
            englishLanguageName: 'Indonesian',
        },
        {
            languageCode: 'ig',
            languageName: 'Igbo',
            englishLanguageName: 'Igbo',
        },
        {
            languageCode: 'ga',
            languageName: 'Gaeilge',
            englishLanguageName: 'Irish',
        },
        {
            languageCode: 'it',
            languageName: 'Italiano',
            englishLanguageName: 'Italian',
        },
        {
            languageCode: 'ja',
            languageName: '日本語',
            englishLanguageName: 'Japanese',
        },
        {
            languageCode: 'jv',
            languageName: 'ꦧꦱꦗꦮ',
            englishLanguageName: 'Javanese',
        },
        {
            languageCode: 'kn',
            languageName: 'ಕನ್ನಡ',
            englishLanguageName: 'Kannada',
        },
        {
            languageCode: 'km',
            languageName: 'ភាសាខ្មែរ',
            englishLanguageName: 'Khmer',
        },
        {
            languageCode: 'ko',
            languageName: '한국어',
            englishLanguageName: 'Korean',
        },
        {
            languageCode: 'ks',
            languageName: 'کٲشُر',
            englishLanguageName: 'Kashmiri',
        },
        {
            languageCode: 'ku',
            languageName: 'Kurdî',
            englishLanguageName: 'Kurdish',
        },
        {
            languageCode: 'lo',
            languageName: 'ລາວ',
            englishLanguageName: 'Lao',
        },
        {
            languageCode: 'lt',
            languageName: 'Lietuvių',
            englishLanguageName: 'Lithuanian',
        },
        {
            languageCode: 'lv',
            languageName: 'Latviešu',
            englishLanguageName: 'Latvian',
        },
        {
            languageCode: 'ln',
            languageName: 'Lingála',
            englishLanguageName: 'Lingala',
        },
        {
            languageCode: 'mg',
            languageName: 'Malagasy',
            englishLanguageName: 'Malagasy',
        },
        {
            languageCode: 'ml',
            languageName: 'മലയാളം',
            englishLanguageName: 'Malayalam',
        },
        {
            languageCode: 'mn',
            languageName: 'Монгол',
            englishLanguageName: 'Mongolian',
        },
        {
            languageCode: 'mt',
            languageName: 'Maltese',
            englishLanguageName: 'Maltese',
        },
        {
            languageCode: 'mr',
            languageName: 'मराठी',
            englishLanguageName: 'Marathi',
        },
        {
            languageCode: 'ms',
            languageName: 'Bahasa Melayu',
            englishLanguageName: 'Malay/Indonesian',
        },
        {
            languageCode: 'ne',
            languageName: 'नेपाली',
            englishLanguageName: 'Nepali',
        },
        {
            languageCode: 'nl',
            languageName: 'Nederlands',
            englishLanguageName: 'Dutch',
        },
        {
            languageCode: 'pa',
            languageName: 'ਪੰਜਾਬੀ',
            englishLanguageName: 'Punjabi',
        },
        {
            languageCode: 'pl',
            languageName: 'Polski',
            englishLanguageName: 'Polish',
        },
        {
            languageCode: 'pt',
            languageName: 'Português',
            englishLanguageName: 'Portuguese',
        },
        {
            languageCode: 'pom',
            languageName: 'Pomeranisch',
            englishLanguageName: 'Pomeranian',
        },
        {
            languageCode: 'qu',
            languageName: 'Runa Simi',
            englishLanguageName: 'Quechua',
        },
        {
            languageCode: 'ro',
            languageName: 'Română',
            englishLanguageName: 'Romanian',
        },
        {
            languageCode: 'ru',
            languageName: 'Русский',
            englishLanguageName: 'Russian',
        },
        {
            languageCode: 'rw',
            languageName: 'Kinyarwanda',
            englishLanguageName: 'Kinyarwanda',
        },
        {
            languageCode: 'si',
            languageName: 'සිංහල',
            englishLanguageName: 'Sinhala',
        },
        {
            languageCode: 'sk',
            languageName: 'Slovenčina',
            englishLanguageName: 'Slovak',
        },
        {
            languageCode: 'sl',
            languageName: 'Slovenščina',
            englishLanguageName: 'Slovenian',
        },
        {
            languageCode: 'srn',
            languageName: 'Sranan Tongo',
            englishLanguageName: 'Sranan Tongo',
        },
        {
            languageCode: 'sw',
            languageName: 'Kiswahili',
            englishLanguageName: 'Swahili',
        },
        {
            languageCode: 'sv',
            languageName: 'Svenska',
            englishLanguageName: 'Swedish',
        },
        {
            languageCode: 'ta',
            languageName: 'தமிழ்',
            englishLanguageName: 'Tamil',
        },
        {
            languageCode: 'te',
            languageName: 'తెలుగు',
            englishLanguageName: 'Telugu',
        },
        {
            languageCode: 'tr',
            languageName: 'Türkçe',
            englishLanguageName: 'Turkish',
        },
        {
            languageCode: 'tly',
            languageName: 'Talian',
            englishLanguageName: 'Talian',
        },
        {
            languageCode: 'tl',
            languageName: 'Tagalog',
            englishLanguageName: 'Tagalog/Filipino',
        },
        {
            languageCode: 'uk',
            languageName: 'Українська',
            englishLanguageName: 'Ukrainian',
        },
        {
            languageCode: 'ur',
            languageName: 'اردو',
            englishLanguageName: 'Urdu',
        },
        {
            languageCode: 'vi',
            languageName: 'Tiếng Việt',
            englishLanguageName: 'Vietnamese',
        },
        {
            languageCode: 'wo',
            languageName: 'Wolof',
            englishLanguageName: 'Wolof',
        },

        {
            languageCode: 'xh',
            languageName: 'isiXhosa',
            englishLanguageName: 'Xhosa',
        },
        {
            languageCode: 'yo',
            languageName: 'Yorùbá',
            englishLanguageName: 'Yoruba',
        },
        {
            languageCode: 'zu',
            languageName: 'isiZulu',
            englishLanguageName: 'Zulu',
        },
    ],
    rest: {
        v2: {
            templates: 'https://dev-back.nocode-x.com/v2/templates/',
            parts: 'https://dev-back.nocode-x.com/v2/parts/',
            partTypes: 'https://dev-back.nocode-x.com/v2/part-types/',
            templateInheritances: 'https://dev-back.nocode-x.com/v2/template-inheritances/',
            layers: 'https://dev-back.nocode-x.com/v2/layers/',
            data: 'https://dev-back.nocode-x.com/v2/data/',
            dataFormat: 'https://dev-back.nocode-x.com/v2/data-format/',
            dataVersions: 'https://dev-back.nocode-x.com/v2/data-versions/',
            designSystem: 'https://dev-back.nocode-x.com/designsystem/',
            job: 'https://dev-back.nocode-x.com/v2/job/',
            api: 'https://dev-back.nocode-x.com/v2/api/',
            apiVersions: 'https://dev-back.nocode-x.com/v2/api-versions/',
            applications: 'https://dev-back.nocode-x.com/v2/applications/',
            applicationVersions: 'https://dev-back.nocode-x.com/v2/application-versions/',
            actionContext: 'https://dev-back.nocode-x.com/v2/action-context/',
            actions: 'https://dev-back.nocode-x.com/v2/actions/',
            actionTests: 'https://dev-back.nocode-x.com/v2/action-tests/',
            actionLinks: 'https://dev-back.nocode-x.com/v2/action-links/',
            actionLogs: 'https://dev-back.nocode-x.com/action-logs/',
            auditLogs: 'https://dev-back.nocode-x.com/audit-logs/',
            methods: 'https://dev-back.nocode-x.com/v2/methods/',
            tagLinks: 'https://dev-back.nocode-x.com/v2/tag-links/',
            tags: 'https://dev-back.nocode-x.com/v2/tags/',
            media: 'https://dev-back.nocode-x.com/v2/media/',
            roles: 'https://dev-back.nocode-x.com/roles/',
            groups: 'https://dev-back.nocode-x.com/groups/',
            roleGroup: 'https://dev-back.nocode-x.com/rolegroups/',
            usergroups: 'https://dev-back.nocode-x.com/usergroups/',
            userroles: 'https://dev-back.nocode-x.com/userroles/',
            calculatedRights: 'https://dev-back.nocode-x.com/calculated-rights/',
            companyuser: 'https://dev-back.nocode-x.com/company-users/',
            billingentries: 'https://dev-back.nocode-x.com/v2/billing-entries/',
            billingpackage: 'https://dev-back.nocode-x.com/v2/billing-package/',
            products: 'https://dev-back.nocode-x.com/marketplace/products/',
            installations: 'https://dev-back.nocode-x.com/marketplace/installations/',
            authorizationLinks: 'https://dev-back.nocode-x.com/v2/authorization-links/',
            tasks: 'https://dev-back.nocode-x.com/tasks/',
            assistant: {
                generatetestdata: 'https://dev-back.nocode-x.com/assistant/generate-test-data/',
                generatetheme: 'https://dev-back.nocode-x.com/assistant/generate-theme/',
                generaterootpage: 'https://dev-back.nocode-x.com/assistant/generate-root-page/',
                generateoverviewpage: 'https://dev-back.nocode-x.com/assistant/generate-overview-page/',
                generateformpage: 'https://dev-back.nocode-x.com/assistant/generate-form-page/',
                generatedetailpage: 'https://dev-back.nocode-x.com/assistant/generate-detail-page/',
                generatecsvupload: 'https://dev-back.nocode-x.com/assistant/generate-csv-upload/',
                generateapis: 'https://dev-back.nocode-x.com/assistant/generate-api-endpoints/',
                generatedataformat: 'https://dev-back.nocode-x.com/assistant/generate-data-format/',
            },
        },
        users: 'https://dev-back.nocode-x.com/users/',
        company: 'https://dev-back.nocode-x.com/companies/',
    },
    basepath: 'codex',
    enableDesignSystem: true,
    enableJobs: true,
    useHttpInterceptor: true,
    disableAnimations: false,
    newCompanySelector: true,
    addCompany: true,
    enableConsole: true,
    addPaddingToGrid: false,
    resizing: true,
    testableCompanies: [
        '183e4f41-fe7e-469a-8dbb-ed83d5323227',
        '52e15701-e562-4edb-9d9c-7efb3ce53a7f',
        'a4ec9c4f-1261-47e8-8478-4a974561117c',
        'e532f5f2-0e2b-4fc6-aaec-dbb1e4daee9b',
        '061567e7-35d9-4219-9c9f-be2439000a33',
        'e5ea3c4d-0fc7-4257-9a9a-854eb804dbc2',
        '7929da41-39b0-44be-b8d1-a237667b9bb0',
    ],
};
