import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';

export class ImagePartDetail extends PartDetail {
    code: string;
    url: string;
    processedUrl: string;
    linkType: string;
    mediaId: string;
    automaticResizing: boolean;
}

export async function replaceArguments(detail: ImagePartDetail, templateArguments: TemplateArgument[]): Promise<void> {
    detail.processedUrl = await replaceArgumentsForString(detail.url, templateArguments);
}
