import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApplicationService } from '../../service/application.service';
import {
    requestApplicationInformation,
    requestApplicationInformationError,
    requestApplicationInformationSuccess,
    requestInitAuthentication,
    requestInitAuthenticationSuccess,
} from './application.actions';
import { from, of } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ApplicationDto } from '../../dto/application.dto.interface';
import { KeycloakService } from 'keycloak-angular';
import { frontofficeEnvironment } from '@shared/environment';
import { Router } from '@angular/router';
import { TemplateFacade } from '../../../../../../libs/frontoffice/data-access/template/src/lib/facade/template.facade';

@Injectable()
export class Effects {
    constructor(
        private applicationService: ApplicationService,
        private templateFacade: TemplateFacade,
        protected readonly actions$: Actions,
        private keycloakService: KeycloakService,
        @Inject(DOCUMENT) private document: Document,
        private router: Router
    ) {}

    requestApplicationInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestApplicationInformation),
            switchMap(({ host, redirectToHome }) => {
                return this.applicationService.getApplication(host).pipe(
                    map(application => requestApplicationInformationSuccess({ application, host, redirectToHome })),
                    catchError(() => of(requestApplicationInformationError()))
                );
            })
        )
    );

    requestApplicationInformationSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestApplicationInformationSuccess),
            map(({ application, host, redirectToHome }) => {
                //DEPRECATED, FONTS ARE now being loaded as part of the css. However this should remain here to be backwards compatible.
                this.addFonts(application);
                this.addCss(host + '/v2/application/style.css?v=' + application.uniqueVersionId);
                this.setFavicon(application, host);
                if (redirectToHome) {
                    this.redirectToHomePageOrLoadPage(application);
                }
                return requestInitAuthentication({ application: application });
            })
        )
    );

    requestInitAuthentication$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestInitAuthentication),
            switchMap(({ application }) => {
                return from(this.initKeyCloak(application)).pipe(
                    map(() => {
                        application.initialized = true;
                        return requestInitAuthenticationSuccess({ application });
                    })
                );
            })
        )
    );

    async initKeyCloak(application: ApplicationDto) {
        let realm = application.companyId;
        if (this.document.location.hostname.startsWith('dev-') || this.document.location.hostname === 'localhost') {
            realm = 'dev-' + realm;
        } else if (this.document.location.hostname.startsWith('test-')) {
            realm = 'test-' + realm;
        } else if (this.document.location.hostname.startsWith('accept-')) {
            realm = 'accept-' + realm;
        }
        await this.keycloakService.init({
            config: {
                url: `${frontofficeEnvironment.amLink}`,
                realm: realm,
                clientId: 'nocodex-broker',
            },
            initOptions: {
                //onLoad: 'check-sso',
                //redirectUri: this.getRedirectUrl(application),
                checkLoginIframe: false,
            },
        });
        await this.keycloakService.getToken();
    }

    getRedirectUrl(application: ApplicationDto) {
        if (application.loginRedirectPath && application.loginRedirectHost) {
            if (location.host.startsWith('localhost')) {
                return (
                    frontofficeEnvironment.redirectUrl +
                    `?host=${location.protocol}//${location.host}&template=${application.loginRedirectPath}&params=${btoa(location.search)}`
                );
            } else {
                const hostNameParts = location.hostname.split('.');
                const subdomain = hostNameParts.shift();
                return (
                    frontofficeEnvironment.redirectUrl +
                    `?host=${location.protocol}//${application.loginRedirectHost + '.' + hostNameParts.join('.')}&template=${
                        application.loginRedirectPath
                    }&params=${btoa(location.search)}`
                );
            }
        } else {
            return (
                frontofficeEnvironment.redirectUrl +
                `?host=${location.protocol}//${location.host}&template=${location.pathname}&params=${btoa(location.search)}`
            );
        }
    }

    addFonts(application: ApplicationDto) {
        application.fontFamilies.forEach(fontFamily => this.addCss('https://fonts.googleapis.com/css2?family=' + fontFamily));
    }

    addCss(cssFile: string) {
        const head: HTMLElement = this.document.getElementsByTagName('head')[0];
        if (!!head) {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = cssFile;
            link.media = 'all';
            head.appendChild(link);
        }
    }

    redirectToHomePageOrLoadPage(application: ApplicationDto) {
        const path = this.router.url;
        if ((path === '' || path === '/') && !!application.homeUrl && application.homeUrl !== path) {
            this.router.navigateByUrl(application.homeUrl);
            return of(null);
        }
    }

    setFavicon(application: ApplicationDto, host: string) {
        const favIcon: HTMLLinkElement = this.document.querySelector('#appIcon');
        if (!!favIcon) {
            favIcon.href = host + '/v2/application/favicon?v=' + application.uniqueVersionId;
        }
    }
}
