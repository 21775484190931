import { ApplicationDto } from '../../dto/application.dto.interface';
import { createReducer, on } from '@ngrx/store';
import {
    requestApplicationInformationError,
    requestApplicationInformationSuccess,
    requestInitAuthenticationSuccess,
} from './application.actions';

export const applicationReducer = (initialData: ApplicationDto) =>
    createReducer(
        initialData,
        on(requestInitAuthenticationSuccess, (_, { application }) => application),
        on(requestApplicationInformationError, () => undefined)
    );
