export class LineChartSeriesOptions {
    readonly type = 'line';
    id?: string;
    name?: string;
    data: string[] = [];
    lineStyle?: LineChartLineStyleOptions;
    itemStyle?: LineChartLineStyleOptions;

    constructor(base?: Partial<LineChartSeriesOptions>) {
        if (base) {
            this.id = base.id ?? this.id;
            this.name = base.name ?? this.name;
            this.data = base.data ?? this.data;
            this.lineStyle = base.lineStyle ?? this.lineStyle;
            this.itemStyle = base.itemStyle ?? this.itemStyle;
        }
    }
}

export class LineChartLineStyleOptions {
    color = '#000';

    constructor(base?: Partial<LineChartLineStyleOptions>) {
        if (base) {
            this.color = base.color ?? this.color;
        }
    }
}

export class LineChartItemStyleOptions {
    color = '#000';

    constructor(base?: Partial<LineChartItemStyleOptions>) {
        if (base) {
            this.color = base.color ?? this.color;
        }
    }
}
