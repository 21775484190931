<div *ngIf="options && uploadInput" [id]="'id-' + part.id">
    <div *ngIf="this.files">
        <div *ngFor="let file of this.files" class="file">{{ file.name }}</div>
    </div>

    <div
        class="drop-container"
        ngFileDrop
        [options]="options"
        (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput"
        [ngClass]="{
            'is-drop-over': dragOver,
            hidden: !this.partDetail.showDropContainer || this.files.length >= this.partDetail.maxUploads
        }">
        <span>{{ partDetail.dragAndDropText }}</span>
    </div>

    <label class="upload-button" *ngIf="this.partDetail.showPickFileButton && this.files.length < this.partDetail.maxUploads">
        <input type="file" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple />
        {{ partDetail.pickFileButtonText }}
    </label>

    <button
        *ngIf="this.partDetail.showUploadButton && this.files.length >= 1"
        type="button"
        (click)="startUpload()"
        color="primary"
        mat-button
        mat-raised-button>
        {{ partDetail.startUploadButtonText }}
    </button>
</div>
