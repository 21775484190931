export class BarChartSeriesOptions {
    readonly type = 'bar';
    id?: string;
    name?: string;
    data: string[] = [];
    lineStyle?: BarChartLineStyleOptions;
    itemStyle?: BarChartItemStyleOptions;

    constructor(base?: Partial<BarChartSeriesOptions>) {
        if (base) {
            this.id = base.id ?? this.id;
            this.name = base.name ?? this.name;
            this.data = base.data ?? this.data;
            this.lineStyle = base.lineStyle ?? this.lineStyle;
            this.itemStyle = base.itemStyle ?? this.itemStyle;
        }
    }
}

export class BarChartLineStyleOptions {
    color = '#000';

    constructor(base?: Partial<BarChartLineStyleOptions>) {
        if (base) {
            this.color = base.color ?? this.color;
        }
    }
}

export class BarChartItemStyleOptions {
    color = '#000';

    constructor(base?: Partial<BarChartItemStyleOptions>) {
        if (base) {
            this.color = base.color ?? this.color;
        }
    }
}
