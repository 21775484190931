<div class="subcontainer" [matTooltip]="partDetail.tooltip" [id]="'id-' + part.id" (click)="onClick()" [attr.style]="generateStyle()">
    <ng-container *ngFor="let child of partDetail.parts; trackBy: identifyPart">
        <ng-container *ngIf="!child.hidden">
            <part
                [attr.id]="'part-' + child.id"
                [partId]="child.id"
                [templateIdentifier]="templateVersion.instanceIdentifier"
                [host]="host"
                [templateVersion]="templateVersion"
                [application]="application"
                [parentFormGroup]="parentFormGroup"
                (executeAction)="executeAction.emit($event)">
            </part>
        </ng-container>
    </ng-container>
</div>
