import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, PartDetail, TemplateVersion } from '@frontoffice/data-access/template';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SlideToggleFieldPartStyle } from '../../model/slide-toggle-field-part.style';
import { replaceArguments, SlideToggleFieldPartDetail } from '../../model/slide-toggle-field-part.detail';
import { from, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { FormField } from '../../../form-field/form-field.component';
import { NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'app-slide-toggle-field-part-front',
    templateUrl: './slide-toggle-field-part-front.component.html',
})
export class SlideToggleFieldPartFrontComponent extends FormField implements ComponentFront, OnInit, OnChanges, OnDestroy {
    partDetail: SlideToggleFieldPartDetail = null;
    partStyle: SlideToggleFieldPartStyle = null;

    part: Part = null;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    parentFormGroup: FormGroup;

    application: ApplicationDto;
    templateVersion: TemplateVersion;

    answerControlName: string;

    subscriptions: Subscription = new Subscription();

    formGroup: FormGroup;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        override fb: FormBuilder
    ) {
        super();
    }

    ngOnInit(): void {
        // This is necessary because else the filter for the onchange subscription will fail undefined != null.
        this.partDetail.processedAnswer = null;
        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.change();
    }

    ngOnDestroy(): void {
        this.destroy();
        this.subscriptions.unsubscribe();
    }

    onChangeAnswer(): void {
        if (this.partDetail.answer !== this.parentFormGroup.get(this.answerControlName).value) {
            this.partDetail.answer = this.parentFormGroup.get(this.answerControlName).value;
            this.executeOnChangeAction();
        }
    }

    executeOnChangeAction(): void {
        const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
            return {
                name: templateArgument?.name,
                value: templateArgument.value,
                calculatedValue: templateArgument.calculatedValue,
                subArguments: templateArgument.subArguments,
            };
        });
        const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CHANGE');
        if (!!actionLinks && actionLinks.length > 0) {
            this.executeAction.emit({
                trigger: this.part.id,
                actionLinks,
                arguments: templateActionArguments,
            });
        }
    }

    override getValue(): any {
        return this.partDetail.processedAnswer;
    }

    override setValue(value: any): void {
        this.partDetail.answer = value;
        this.partDetail.processedAnswer = value;
    }

    override getSubscriptions(): Subscription {
        return this.subscriptions;
    }

    override replaceArguments(): Promise<void> {
        return replaceArguments(this.partDetail, this.templateVersion.arguments);
    }

    override getPartDetail(): PartDetail {
        return this.partDetail;
    }

    override getCode(): string {
        return this.partDetail.code;
    }

    override getType(): string {
        return this.partDetail.partType;
    }

    override getIdentifier(): string | undefined {
        return this.part.instanceIdentifier;
    }
    override isRequired(): boolean {
        return true;
    }

    override isEnabled(): boolean {
        return this.partDetail.enabled;
    }

    override getInvalidMessage(): string | null {
        return null;
    }

    override setInvalidMessage(invalidMessage: string | null): void {
        // DO nothing!
    }

    override getValidationRegex(): string | null {
        return null;
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
