import { TemplateArgument } from '../../../models/template-argument.model';
import { TemplateResult } from './template-result.model';
import { replaceArgumentsForString } from '../../../models/part-arguments';
import { PartDetail } from '../../../models/part-detail.model';
import { TemplateVersion } from '../../../models/template-version.model';
import { Part } from '../../../models/part.model';

export class TemplatePartDetail extends PartDetail {
    code: string;
    templateId: string;
    formGroupId: string;
    arguments: TemplateArgument[];
    processedArguments: TemplateArgument[] = [];
    results: TemplateResult[];
    passArgumentToBackend: boolean;
    templateVersion?: TemplateVersion;

    override getChildParts(): Part[] {
        if (this.templateVersion) {
            return this.templateVersion?.parts ? this.templateVersion?.parts : [];
        } else {
            return [];
        }
    }
}

export function addTemplateResult(templatePartDetail: TemplatePartDetail, templateResult: TemplateResult) {
    if (!templatePartDetail.results) {
        templatePartDetail.results = [];
    }
    const existingResult = templatePartDetail.results.find(result => result.id === templateResult.id);
    if (!existingResult) {
        templatePartDetail.results.push(templateResult);
    } else {
        existingResult.value = templateResult.value;
    }
}

export async function processArguments(_arguments: TemplateArgument[], parentArguments: TemplateArgument[]) {
    const processedArguments = [];
    for (let i = 0; i < _arguments.length; i++) {
        const argument = _arguments[i];
        const processedArgument = Object.assign({}, argument);
        if (processedArgument.value && !processedArgument.calculatedValue && processedArgument.value.startsWith("'")) {
            processedArgument.calculatedValue = await replaceArgumentsForString(
                processedArgument.value.replace(/'/gi, ''),
                parentArguments
            );
        } else if (processedArgument.value && !processedArgument.calculatedValue) {
            processedArgument.calculatedValue = await replaceArgumentsForString(processedArgument.value, parentArguments);
        }

        if (processedArgument.subArguments && processedArgument.subArguments.length > 0) {
            processedArgument.subArguments = await processArguments(processedArgument.subArguments, parentArguments);
        }

        processedArguments.push(processedArgument);
    }
    return processedArguments;
}

export async function replaceArguments(detail: TemplatePartDetail, parentArguments: TemplateArgument[]): Promise<TemplateArgument[]> {
    let newProcessedArguments: TemplateArgument[] = [];
    if (detail?.arguments) {
        newProcessedArguments = await processArguments(detail.arguments, parentArguments);
    }
    return newProcessedArguments;
}
