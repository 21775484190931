import { ApplicationRef, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { appRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClientXsrfModule, HttpRequest } from '@angular/common/http';
import { NgxEchartsModule } from 'ngx-echarts';
import { ApplicationService } from './service/application.service';
import { NgHttpCachingConfig, NgHttpCachingEntry, NgHttpCachingModule } from 'ng-http-caching';
import { KeycloakAngularModule } from 'keycloak-angular';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { ExternalAppComponent } from './external-app/external-app.component';
import { createCustomElement } from '@angular/elements';
import { MatIconModule } from '@angular/material/icon';
import { SharedTemplateModule } from './shared-template/shared-template.module';
import { StoreModule } from '@ngrx/store';
import { appReducers } from './store/app.reducer';
import { Effects } from './store/application/application.effects';
import { EffectsModule } from '@ngrx/effects';
import { TemplateversionDialogComponent } from './templateversion-dialog/templateversion-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import * as hash from 'object-hash';
import { FrontofficeFeatureInterpretionModule } from '@frontoffice/feature/interpretion';
import { FrontofficeDataAccessTemplateModule } from '@frontoffice/data-access/template';
import { TemplateversionModule } from './templateversion/templateversion.module';
import { TemplateEffects } from './store/template/template.effects';
import { PartEffects } from './store/part/part.effects';
import { HttpXsrfInterceptorProvider } from './http/http-csrf.interceptor';
import { HttpErrorInterceptorProvider } from './http/http-error.interceptor';
import { HttpAuthenticationInterceptorProvider } from './http/http-authentication.interceptor';
import { HttpLoaderInterceptorProvider } from './http/http-loader.interceptor';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { backofficeEnvironment } from '@shared/environment';

const hashOptions = {
    algorithm: 'md5',
    encoding: 'hex',
};

const ngHttpCachingConfig: NgHttpCachingConfig = {
    // Only use maximum 500ms for now!
    // Until we eliminate our custom HttpClient in favor of interceptors and gain more control over
    // Clearing cache in case of put,post,deletes.
    lifetime: 2000,
    allowedMethod: ['GET', 'POST'],
    getKey: (req: HttpRequest<any>): string | undefined => {
        return req.method + '@' + req.urlWithParams + '@' + hash(req.params, hashOptions);
    },
    isExpired: (entry: NgHttpCachingEntry): boolean | undefined => {
        if (entry.request.params.has('hc') && 'true' === entry.request.params.get('hc')) {
            return entry.addedTime + 60000 < Date.now();
        }
        if (entry.request.urlWithParams.startsWith('https://dev-')) {
            return entry.addedTime + 1000 < Date.now();
        }

        // by returning "undefined" normal "ng-http-caching" workflow is applied
        return undefined;
    },
    isCacheable: (req: HttpRequest<any>): boolean | undefined => {
        if (req.urlWithParams.indexOf('/template-version') !== -1) {
            return true;
        } else if (req.urlWithParams.indexOf('/action-executions') !== -1) {
            return false;
        }
        // by returning "undefined" normal "ng-http-caching" workflow is applied
        return undefined;
    },
};

@NgModule({
    declarations: [AppComponent, ExternalAppComponent, TemplateversionDialogComponent],
    imports: [
        SharedTemplateModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        KeycloakAngularModule,
        MatIconModule,
        MatDialogModule,
        MatSnackBarModule,
        NgxFloatUiModule.forRoot(),
        HttpClientXsrfModule,
        TemplateversionModule,
        NgHttpCachingModule.forRoot(ngHttpCachingConfig),
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        StoreModule.forRoot(appReducers, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
        EffectsModule.forRoot([Effects, TemplateEffects, PartEffects]),
        /*StoreDevtoolsModule.instrument({
            maxAge: 100,
            logOnly: backofficeEnvironment.production,
        }),*/
        FrontofficeFeatureInterpretionModule,
        FrontofficeDataAccessTemplateModule,
        appRoutingModule,
    ],
    providers: [
        ApplicationService,
        HttpXsrfInterceptorProvider,
        HttpErrorInterceptorProvider,
        HttpAuthenticationInterceptorProvider,
        HttpLoaderInterceptorProvider,
        { provide: Window, useValue: window },
    ],
})
export class AppModule {
    constructor(public injector: Injector) {
        this.createWebComponents();
    }

    public ngDoBootstrap(appRef: ApplicationRef): void {
        if (document.querySelector('app-root')) {
            appRef.bootstrap(AppComponent);
        }
    }

    createWebComponents(): void {
        const el = createCustomElement(ExternalAppComponent, { injector: this.injector });
        customElements.define('nocodex-page', el);
    }
}
