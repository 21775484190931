import { Inject, Injectable } from '@angular/core';
import { frontofficeEnvironment } from '@shared/environment';
import { filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { TemplateService } from '../service/template.service';
import { ApplicationDto } from '../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { MatDialogRef } from '@angular/material/dialog';
import { TemplateArgument } from '../models/template-argument.model';
import { GUIDFunctions } from '@shared/utils';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../apps/no-code-x-frontoffice/src/app/store/app.state';
import {
    selectExternalApp,
    templateBaseSelectors,
    templateByHostSelectors,
    templateByIdSelectors,
} from '../../../../../../../apps/no-code-x-frontoffice/src/app/store/template/template.selector';
import { partSelectors } from '../../../../../../../apps/no-code-x-frontoffice/src/app/store/part/part.selector';
import {
    removeTemplateInstanceFromStore,
    requestChangeExternalAppTemplate,
    requestExecuteAction,
    requestExecuteOnEnterAction,
    requestFetchTemplateByHost,
    requestFetchTemplateById,
} from '../../../../../../../apps/no-code-x-frontoffice/src/app/store/template/template.action';
import { ActionService } from '../service/action.service';
import { TemplateVersionResponse } from '../models/template-version-response.model';
import { TemplateVersion } from '../models/template-version.model';
import { Part } from '../models/part.model';
import { removePartInstanceFromStore } from '../../../../../../../apps/no-code-x-frontoffice/src/app/store/part/part.actions';

@Injectable()
export class TemplateFacade {
    constructor(
        private templateService: TemplateService,
        private actionService: ActionService,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        private store: Store<AppState>,
        private route: ActivatedRoute
    ) {}

    public removePartInstanceFromStore(instanceIdentifier: string) {
        this.store.dispatch(removePartInstanceFromStore({ instanceIdentifier }));
    }
    public removeTemplateInstanceFromStore(instanceIdentifier: string) {
        this.store.dispatch(removeTemplateInstanceFromStore({ instanceIdentifier }));
    }
    private loadArgumentsFromQueryParameters(paramMap: ParamMap, templateArguments: TemplateArgument[]): TemplateArgument[] {
        const args: TemplateArgument[] = templateArguments ? templateArguments : [];
        paramMap.keys.forEach(paramName => {
            const existingArgument = args.find(argument => argument.name === paramName);
            if (!existingArgument) {
                args.push({
                    value: paramMap.get(paramName),
                    calculatedValue: paramMap.get(paramName),
                    name: paramName,
                });
            } else {
                existingArgument.calculatedValue = paramMap.get(paramName);
            }
        });
        return args;
    }

    fetchOnEntryById(templateId: string, templateArguments: TemplateArgument[], instanceIdentifier: string) {
        return this.store.select(templateByIdSelectors.byId(instanceIdentifier)).pipe(
            filter((template: TemplateVersionResponse | undefined): template is TemplateVersionResponse => !!template),
            map(templateVersionResponse => templateVersionResponse.onEntryActions)
        );
    }

    fetchBaseTemplateResponse(templateId: string) {
        return this.store.select(templateBaseSelectors.byId(templateId)).pipe(
            filter((template: TemplateVersionResponse | undefined): template is TemplateVersionResponse => !!template),
            map(baseTemplateVersionResponse => {
                const templateVersionResponse = Object.assign({}, baseTemplateVersionResponse);
                templateVersionResponse.templateVersion = Object.assign(
                    {},
                    JSON.parse(JSON.stringify(baseTemplateVersionResponse.templateVersion))
                );
                return templateVersionResponse;
            })
        );
    }

    executeOnEnterAction(templateVersionResponse: TemplateVersionResponse, host?: string) {
        this.store.dispatch(requestExecuteOnEnterAction({ host, templateVersionResponse }));
    }

    fetchExternalAppTemplate(templateId: string, templateArguments: TemplateArgument[], host?: string) {
        const path = this.router.url;
        if (!host) {
            host = this.getHost(this.document.location);
        }
        this.store.dispatch(
            requestChangeExternalAppTemplate({
                templateId: templateId,
                templateArguments: this.loadArgumentsFromQueryParameters(this.route.snapshot.queryParamMap, templateArguments),
                instanceIdentifier: 'external-' + templateId,
                host: host,
                path: path,
            })
        );
        return this.store.select(selectExternalApp).pipe(
            switchMap(externalAppState =>
                this.store.select(templateByIdSelectors.byId(externalAppState.instanceIdentifier)).pipe(
                    filter((template: TemplateVersionResponse | undefined): template is TemplateVersionResponse => !!template),
                    map(templateVersionResponse => templateVersionResponse.templateVersion)
                )
            )
        );
    }

    fetchTemplateById(
        templateId: string,
        templateArguments: TemplateArgument[],
        instanceIdentifier: string,
        host?: string | null,
        languageCode?: string | null
    ) {
        const path = this.router.url;
        if (!host) {
            host = this.getHost(this.document.location);
        }
        this.store.dispatch(
            requestFetchTemplateById({
                templateId: templateId,
                host,
                path,
                templateArguments: this.loadArgumentsFromQueryParameters(this.route.snapshot.queryParamMap, templateArguments),
                instanceIdentifier,
                languageCode,
            })
        );
        return this.store.select(templateByIdSelectors.byId(instanceIdentifier)).pipe(
            filter((template: TemplateVersionResponse | undefined): template is TemplateVersionResponse => !!template),
            map(templateVersionResponse => templateVersionResponse.templateVersion)
        );
    }

    prepareRequestExecuteAction(
        host: string | null,
        trigger: string,
        triggerType: string,
        actionIds: string[],
        templateId: string,
        templateArguments: TemplateArgument[],
        executionResultPartId: string,
        byPath: boolean,
        formGroup: FormGroup,
        formTemplateIdentifier: string,
        webComponent: boolean,
        templateVersionResponse?: TemplateVersionResponse
    ) {
        if (!host) {
            host = this.getHost(this.document.location);
        }
        return {
            host,
            trigger,
            triggerType,
            actionIds,
            templateId,
            templateArguments: this.loadArgumentsFromQueryParameters(this.route.snapshot.queryParamMap, templateArguments),
            executionResultPartId,
            byPath,
            formGroup,
            formTemplateIdentifier,
            webComponent,
            templateVersionResponse,
        };
    }

    executeAction(
        trigger: string,
        triggerType: string,
        actionIds: string[],
        templateId: string,
        templateArguments: TemplateArgument[],
        executionResultPartId: string,
        byPath: boolean,
        formGroup: FormGroup,
        formTemplateIdentifier: string,
        host: string | undefined,
        webComponent: boolean = false
    ) {
        this.store.dispatch(
            requestExecuteAction(
                this.prepareRequestExecuteAction(
                    host,
                    trigger,
                    triggerType,
                    actionIds,
                    templateId,
                    templateArguments,
                    executionResultPartId,
                    byPath,
                    formGroup,
                    formTemplateIdentifier,
                    webComponent
                )
            )
        );
    }

    fetchTemplateByPath() {
        const path = location.pathname;
        const instanceIdentifier = location.pathname.slice(1).replace(new RegExp('/', 'g'), '-');
        const host = this.getHost(this.document.location);
        this.store.dispatch(
            requestFetchTemplateByHost({
                host,
                path,
                templateArguments: this.loadArgumentsFromQueryParameters(this.route.snapshot.queryParamMap, []),
                instanceIdentifier,
            })
        );
        return this.store.select(templateByHostSelectors.byId(instanceIdentifier)).pipe(
            filter((template: TemplateVersionResponse | undefined): template is TemplateVersionResponse => !!template),
            map(templateVersionResponse => templateVersionResponse.templateVersion)
        );
    }

    fetchPart(partId: string, templateIdentifier: string) {
        return this.store
            .select(partSelectors.byId(templateIdentifier + '-' + partId))
            .pipe(filter((part: Part | undefined): part is Part => !!part));
    }

    loadTemplateById(
        templateId: string,
        applicationId: string,
        companyId: string,
        templateArguments: TemplateArgument[],
        paramMap: ParamMap,
        application: ApplicationDto,
        dialogRefs: MatDialogRef<any>[],
        host?: string | null
    ) {
        const path = this.router.url;
        if (!host) {
            host = this.getHost(this.document.location);
        }
        return this.templateService
            .getTemplateVersionByIdAndArgumentsAndParams(
                host,
                templateId,
                applicationId,
                companyId,
                templateArguments,
                paramMap,
                !frontofficeEnvironment.onEntryOnBackend || true,
                frontofficeEnvironment.onEntryOnBackend
            )
            .pipe(
                switchMap(templateVersionResponse => {
                    if (
                        !frontofficeEnvironment.onEntryOnBackend &&
                        templateVersionResponse.onEntryActions &&
                        templateVersionResponse.onEntryActions.length > 0
                    ) {
                        const _arguments = this.loadArgumentsFromQueryParameters(paramMap, templateArguments);
                        return this.actionService.createActionExecution(host, companyId, {
                            trigger: templateVersionResponse.templateVersion.templateDto.id,
                            triggerType: 'ON_ENTER',
                            actionIds: templateVersionResponse.onEntryActions,
                            templateVersion: TemplateVersion.prepareForAction(templateVersionResponse.templateVersion),
                            applicationId: applicationId,
                            companyId: companyId,
                            arguments: _arguments,
                            currentUrl: {
                                path: window.location.pathname,
                                domain: window.location.host,
                            },
                            browser: {
                                language: navigator.language,
                                online: navigator.onLine,
                                userAgent: navigator.userAgent,
                            },
                        });
                    } else {
                        return of({
                            templateVersion: templateVersionResponse.templateVersion,
                            frontEndActions: templateVersionResponse.frontEndActions,
                        });
                    }
                })
            );
    }

    loadTemplate(
        applicationId: string,
        companyId: string,
        templateArguments: TemplateArgument[],
        paramMap: ParamMap,
        application: ApplicationDto,
        dialogRefs: MatDialogRef<any>[],
        host?: string | null
    ) {
        const path = this.router.url;
        if (!host) {
            host = this.getHost(this.document.location);
        }
        return this.templateService.getTemplateVersion(host, path, applicationId, companyId, frontofficeEnvironment.onEntryOnBackend).pipe(
            switchMap(templateVersionResponse => {
                if (
                    !frontofficeEnvironment.onEntryOnBackend &&
                    templateVersionResponse.onEntryActions &&
                    templateVersionResponse.onEntryActions.length > 0
                ) {
                    const _arguments = this.loadArgumentsFromQueryParameters(paramMap, templateArguments);
                    return this.actionService.createActionExecution(host, companyId, {
                        trigger: templateVersionResponse.templateVersion.templateDto.id,
                        triggerType: 'ON_ENTER',
                        actionIds: templateVersionResponse.onEntryActions,
                        templateVersion: TemplateVersion.prepareForAction(templateVersionResponse.templateVersion),
                        applicationId: applicationId,
                        companyId: companyId,
                        arguments: _arguments,
                        currentUrl: {
                            path: window.location.pathname,
                            domain: window.location.host,
                        },
                        browser: {
                            language: navigator.language,
                            online: navigator.onLine,
                            userAgent: navigator.userAgent,
                        },
                    });
                } else {
                    return of({
                        templateVersion: templateVersionResponse.templateVersion,
                        frontEndActions: templateVersionResponse.frontEndActions,
                    });
                }
            })
        );
    }

    createFormResultList(formValues: any) {
        let formResults: {
            code: string | undefined;
            value?: any | null;
            type?: string | null;
            formResults: any[] | null;
        }[] = [];
        console.log(formValues);
        Object.keys(formValues).forEach(formValueKey => {
            const formValue = formValues[formValueKey];
            if (formValue && formValue instanceof Object) {
                if (formValue.type === 'template') {
                    formResults = formResults.concat(this.createFormResultList(formValue));
                } else if (formValue.type === 'group') {
                    const newGroupResult = {
                        code: formValue.code,
                        type: 'group',
                        formResults: [],
                    };
                    if (formValues[formValueKey] instanceof Object) {
                        Object.keys(formValues[formValueKey]).forEach(singleGroupKey => {
                            if (formValues[formValueKey][singleGroupKey] instanceof Object) {
                                newGroupResult.formResults.push({
                                    code: new GUIDFunctions().newGuid(),
                                    formResults:
                                        formValues[formValueKey][singleGroupKey] instanceof Object
                                            ? this.createFormResultList(formValues[formValueKey][singleGroupKey])
                                            : [],
                                });
                            }
                        });
                    }
                    formResults.push(newGroupResult);
                } else {
                    if (formValue.code) {
                        formResults.push({
                            code: formValue.code,
                            value: this.getValueFromFormControl(formValue),
                            formResults: [],
                        });
                    }
                }
            }
        });
        return formResults;
    }

    getValueFromFormControl(formControl: FormControl) {
        if (formControl.value instanceof Object) {
            if (formControl.value instanceof Date) {
                //Timezone offset according to user timezone.
                var offsettedDate = new Date(formControl.value.getTime() - formControl.value.getTimezoneOffset() * 60000);
                return offsettedDate.toISOString();
            }
        } else {
            return formControl.value;
        }
    }

    createFormResults(parentFormGroup: FormGroup, templateVersion: TemplateVersion) {
        if (parentFormGroup) {
            const formValues = parentFormGroup.getRawValue();
            return this.createFormResultList(formValues);
        } else {
            return [];
        }
    }

    private getHost(location: Location) {
        const host = location.hostname;
        if (host !== 'localhost') {
            if (host.indexOf('app.nocode-x.com') > -1) {
                return 'https://' + host.replace('app.nocode-x.com', 'back.nocode-x.com');
            } else {
                if (host.startsWith('dev-')) {
                    return 'https://' + 'dev-back-' + host.replace('dev-', '');
                } else if (host.startsWith('test-')) {
                    return 'https://' + 'test-back-' + +host.replace('test-', '');
                } else if (host.startsWith('accept-')) {
                    return 'https://' + 'accept-back-' + host.replace('accept-', '');
                } else {
                    return 'https://' + 'back-' + host;
                }
            }
        }
        return frontofficeEnvironment.localTestApp ? frontofficeEnvironment.localTestApp : null;
    }
}
