<div *ngIf="partDetail && formGroup" [formGroup]="formGroup" [id]="'id-' + part.id">
    <mat-form-field appearance="outline" dataFormat-name="form-code">
        <mat-label>{{ partDetail.label }}</mat-label>
        <input
            matInput
            [matDatepicker]="formComponentPicker"
            formControlName="value"
            align="end"
            [min]="partDetail.minDate ? partDetail.minDate : null"
            [max]="partDetail.maxDate ? partDetail.maxDate : null"
            [required]="partDetail.required"
            [disabled]="true" />
        <mat-datepicker-toggle matSuffix [for]="formComponentPicker"></mat-datepicker-toggle>
        <mat-datepicker #formComponentPicker disabled="false"></mat-datepicker>
        <mat-error *ngIf="valueControl().touched && valueControl().invalid && partDetail.required && valueControl().hasError('required')"
            >{{ partDetail.requiredMessage }}
        </mat-error>
        <mat-icon
            class="help-icon"
            color="primary"
            *ngIf="partDetail.help"
            matSuffix
            [showTrigger]="NgxFloatUiTriggers.hover"
            appendTo="body"
            [floatUi]="questionInformationPopover"
            >help
        </mat-icon>
        <float-ui-content #questionInformationPopover>
            <div class="popover">
                <div [innerHTML]="partDetail.helpInformation"></div>
            </div>
        </float-ui-content>
    </mat-form-field>
</div>
