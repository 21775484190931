import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateActionExecutionDto } from '../dto/create-action-execution.dto';
import { ActionExecutionCreatedDto } from '../dto/action-execution-created.dto';
import { backofficeEnvironment } from '@shared/environment';

@Injectable({
    providedIn: 'root',
})
export class ActionService {
    constructor(
        private httpClient: HttpClient,
        private _zone: NgZone
    ) {}

    public createActionExecution(
        host: string,
        companyId: string,
        createActionExecutionDto: CreateActionExecutionDto
    ): Observable<ActionExecutionCreatedDto> {
        const params = new HttpParams().set('companyId', companyId);
        return this.httpClient.post<ActionExecutionCreatedDto>(`${host}/v2/action-executions`, createActionExecutionDto, {
            params: params,
        });
    }

    streamActionUpdates(host: string, otp: string): Observable<any> {
        return new Observable(observer => {
            const eventSource = new EventSource(`${host}/v2/action-executions/stream/${otp}`);
            eventSource.onmessage = event => {
                this._zone.run(() => {
                    console.log('hier is er iets!!! shizzl!!!!');
                    console.log(event);
                    observer.next(event);
                    eventSource.close();
                });
            };
            eventSource.onerror = error => {
                this._zone.run(() => {
                    console.log('error going down');
                    console.log(error);
                    eventSource.close();
                });
            };
        });
    }
}
