import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePartFrontComponent } from './components/image-part-front/image-part-front.component';
import { getPartDetailComponents } from '../../../part-module';

@NgModule({
    declarations: [ImagePartFrontComponent],
    imports: [CommonModule],
})
export class ImagePartModule {
    constructor() {
        getPartDetailComponents().set('image', ImagePartFrontComponent);
    }
}
