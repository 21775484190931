<div *ngIf="partDetail && formGroup" [formGroup]="formGroup" [id]="'id-' + part.id">
    <mat-form-field appearance="outline">
        <mat-label>{{ this.partDetail.processedLabel }}</mat-label>
        <mat-select formControlName="value" [required]="partDetail.required" [disabled]="!this.partDetail.enabled">
            <mat-option *ngIf="this.partDetail.emptyChoice" [value]="''">None</mat-option>
            <mat-option *ngFor="let choice of this.partDetail.choices" [value]="choice.code">{{ choice.label }}</mat-option>
        </mat-select>
        <mat-error *ngIf="valueControl().touched && valueControl().invalid && partDetail.required && valueControl().hasError('required')">{{
            partDetail.processedRequiredMessage
        }}</mat-error>
        <mat-icon
            class="help-icon"
            color="primary"
            *ngIf="partDetail.help"
            matSuffix
            [showTrigger]="NgxFloatUiTriggers.hover"
            appendTo="body"
            [floatUi]="questionInformationPopover"
            >help
        </mat-icon>
        <float-ui-content #questionInformationPopover>
            <div class="popover">
                <div [innerHTML]="partDetail.processedHelpInformation"></div>
            </div>
        </float-ui-content>
    </mat-form-field>
</div>
