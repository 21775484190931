<div *ngIf="partDetail && partDetail.pages > 1" [id]="'id-' + part.id">
    <div style="gap: 0.25rem; justify-content: flex-end; display: flex">
        <div style="align-items: center; display: flex">
            <span
                >{{ partDetail.pageTextProcessed }} {{ partDetail.currentPage }} {{ partDetail.ofTextProcessed }}
                {{ partDetail.pages }}</span
            >
        </div>
        <button mat-mini-fab [disabled]="isFirstPage()" (click)="onPreviousPage()">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <button mat-mini-fab [disabled]="isLastPage()" (click)="onNextPage()">
            <mat-icon>chevron_right</mat-icon>
        </button>
    </div>
</div>
