<mat-stepper
    *ngIf="partDetail && stepsInitialized"
    [orientation]="(stepperOrientation | async)!"
    linear
    #stepper
    class="subcontainer"
    [id]="'id-' + part.id"
    (selectionChange)="onStepOpened($event)"
    (animationDone)="this.initResizeObservers()">
    <mat-step *ngFor="let step of partDetail.steps; index as i; trackBy: identifyStep" [state]="step.code" [stepControl]="parentFormGroup">
        <ng-template matStepLabel>{{ step.name }}</ng-template>
        <div class="secondarycontainer" *ngIf="this.openedSteps.has(i)">
            <div class="stepper-controls stepper-controls-top">
                <button
                    mat-button
                    preventDoubleClick
                    [disabledByPart]="false"
                    (click)="executeOnPreviousAction(step?.code, partDetail.steps[i - 1]?.code)"
                    *ngIf="step.previousButtonLabel"
                    matStepperPrevious>
                    {{ step.previousButtonLabel }}
                </button>
                <button
                    mat-button
                    preventDoubleClick
                    [disabledByPart]="false"
                    (click)="executeOnSaveAction(step?.code)"
                    *ngIf="step.saveButtonLabel">
                    {{ step.saveButtonLabel }}
                </button>
                <button
                    mat-button
                    preventDoubleClick
                    [disabledByPart]="false"
                    (click)="executeOnNextAction(step?.code, partDetail.steps[i + 1]?.code)"
                    *ngIf="step.nextButtonLabel"
                    matStepperNext>
                    {{ step.nextButtonLabel }}
                </button>
            </div>
            <ng-container *ngFor="let child of step.parts; trackBy: identifyPart">
                <ng-container *ngIf="!child.hidden">
                    <stepper-part
                        [attr.id]="'part-' + child.id"
                        [part]="child"
                        [partContainer]="this.part"
                        [host]="host"
                        [templateVersion]="templateVersion"
                        [application]="application"
                        [parentFormGroup]="parentFormGroup"
                        (executeAction)="executeAction.emit($event)">
                    </stepper-part>
                </ng-container>
            </ng-container>
            <div class="stepper-controls stepper-controls-bottom">
                <button
                    mat-button
                    preventDoubleClick
                    [disabledByPart]="false"
                    (click)="executeOnPreviousAction(step?.code, partDetail.steps[i - 1]?.code)"
                    *ngIf="step.previousButtonLabel"
                    matStepperPrevious>
                    {{ step.previousButtonLabel }}
                </button>
                <button
                    mat-button
                    preventDoubleClick
                    [disabledByPart]="false"
                    (click)="executeOnSaveAction(step?.code)"
                    *ngIf="step.saveButtonLabel">
                    {{ step.saveButtonLabel }}
                </button>
                <button
                    mat-button
                    preventDoubleClick
                    [disabledByPart]="false"
                    (click)="executeOnNextAction(step?.code, partDetail.steps[i + 1]?.code)"
                    *ngIf="step.nextButtonLabel"
                    matStepperNext>
                    {{ step.nextButtonLabel }}
                </button>
            </div>
        </div>
    </mat-step>
    <ng-container *ngFor="let step of partDetail.steps">
        <ng-template *ngIf="step.icon" [matStepperIcon]="step.code">
            <mat-icon>{{ step.icon }}</mat-icon>
        </ng-template>
    </ng-container>
</mat-stepper>
