import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperPartFrontComponent } from './components/stepper-part-front/stepper-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { PartComponent } from './components/part/part.component';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { CommonsModule } from '../../../../../../../../../apps/no-code-x-frontoffice/src/app/lib/common/commons.module';

@NgModule({
    declarations: [StepperPartFrontComponent, PartComponent],
    imports: [
        CommonModule,
        CommonsModule,
        MatStepperModule,
        MatIconModule,
        MatButtonModule,
        MatRippleModule,
        FormsModule,
        ReactiveFormsModule,
    ],
})
export class StepperPartModule {
    constructor() {
        getPartDetailComponents().set('stepper', StepperPartFrontComponent);
    }
}
