import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { LineChartPartDetail } from '../../model/line-chart-part.detail';
import { LineChartPartStyle } from '../../model/line-chart-part.style';
import { ChartTitleOptions } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/dto/chart-title-options.interface';
import {
    LineChartItemStyleOptions,
    LineChartLineStyleOptions,
    LineChartSeriesOptions,
} from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/dto/line-chart-series-options.interface';
import { ChartLegendOptions } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/dto/chart-legend-options.interface';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

@Component({
    selector: 'app-line-chart-part-front',
    templateUrl: './line-chart-part-front.component.html',
    styleUrls: ['./line-chart-part-front.component.scss'],
})
export class LineChartPartFrontComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: LineChartPartDetail;
    partStyle: LineChartPartStyle;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    parentFormGroup: FormGroup;

    get part(): Part {
        return this._part;
    }

    set part(value: Part) {
        this._part = value;
        this._handleDetailChanged();
    }

    _part: Part;

    partUpdated: EventEmitter<{ part: Part }> = null;

    options: any = {
        xAxis: {},
        yAxis: {},
        series: [],
    };

    chartInstance: any;

    constructor(public changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.chartInstance) {
            this.chartInstance.resize();
        }
    }

    onChartInit(e: any): void {
        this.chartInstance = e;
        this.chartInstance.resize();
    }

    private _handleDetailChanged(): void {
        if (!!this._part) {
            const partDetail = this._part.detail as LineChartPartDetail;
            const partStyle = this._part.style as LineChartPartStyle;

            const titleOptions: ChartTitleOptions = {
                text: partDetail?.title,
            };

            const { xaxis: axisInfo } = partDetail;
            const xAxis = {
                name: axisInfo?.name,
                data: axisInfo?.points?.split(';') ?? [],
                silent: false,
                splitLine: {
                    show: false,
                },
            };

            const mapping =
                partDetail?.datasources?.map(sourceDetail => {
                    const sourceStyle = partStyle?.series.find(x => x.id === sourceDetail.id);
                    return {
                        detail: sourceDetail,
                        style: sourceStyle,
                    };
                }) ?? [];

            const series =
                mapping.map(source => {
                    const { detail, style } = source;
                    return new LineChartSeriesOptions({
                        id: detail.id,
                        name: detail?.name,
                        data: detail?.points?.split(';'),
                        lineStyle: new LineChartLineStyleOptions({ color: style?.itemStyle?.color }),
                        itemStyle: new LineChartItemStyleOptions({ color: style?.itemStyle?.color }),
                    });
                }) ?? [];

            const { legend } = partStyle;

            const legendOptions = new ChartLegendOptions({
                show: legend?.show,
                orient: legend?.orient,
                left: legend?.left,
                top: legend?.top,
                data: mapping.map(source => ({ name: source.detail.name })),
                align: 'right',
            });

            this.options = {
                ...this.options,
                xAxis,
                yAxis: {},
                series,
                title: titleOptions,
                legend: legendOptions,
                tooltip: {
                    trigger: 'item',
                },
                grid: {
                    left: partStyle.gridMarginStyle?.marginLeftStyle?.margin
                        ? partStyle.gridMarginStyle?.marginLeftStyle?.margin
                        : partStyle.gridMarginStyle?.marginStyle?.margin,
                    right: partStyle.gridMarginStyle?.marginRightStyle?.margin
                        ? partStyle.gridMarginStyle?.marginRightStyle?.margin
                        : partStyle.gridMarginStyle?.marginStyle?.margin,
                    bottom: partStyle.gridMarginStyle?.marginBottomStyle?.margin
                        ? partStyle.gridMarginStyle?.marginBottomStyle?.margin
                        : partStyle.gridMarginStyle?.marginStyle?.margin,
                    top: partStyle.gridMarginStyle?.marginTopStyle?.margin
                        ? partStyle.gridMarginStyle?.marginTopStyle?.margin
                        : partStyle.gridMarginStyle?.marginStyle?.margin,
                },
            };
        } else {
            this.options = {};
        }
    }
}
