import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagePartDetail, replaceArguments } from '../../model/image-part.detail';
import { ImagePartStyle } from '../../model/image-part.style';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

declare let jQuery: any;

@Component({
    selector: 'app-image-part-front',
    templateUrl: './image-part-front.component.html',
    styleUrls: ['./image-part-front.component.scss'],
})
export class ImagePartFrontComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: ImagePartDetail = null;
    partStyle: ImagePartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    parentFormGroup: FormGroup;

    host = '';

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public sanitizer: DomSanitizer
    ) {}

    async ngOnInit(): Promise<void> {
        await replaceArguments(this.partDetail, this.templateVersion.arguments);
        this.handleAutomaticResizing();
    }

    ngOnChanges(changes: SimpleChanges): void {
        replaceArguments(this.partDetail, this.templateVersion.arguments);
    }

    handleAutomaticResizing() {
        if (this.partDetail.automaticResizing) {
            let width = jQuery('#id-' + this.part.id).width();
            let height = jQuery('#id-' + this.part.id).height();
            if (jQuery('#id-' + this.part.id).css('object-fit') === 'cover') {
                width = width * 2;
                height = height * 2;
            }
            jQuery('#id-' + this.part.id).attr(
                'src',
                this.host +
                    '/cdn-cgi/image/width=' +
                    width +
                    ',height=' +
                    height +
                    ',fit=scale-down,quality=100,format=auto/v2/media/file/' +
                    this.partDetail.mediaId
            );
        }
    }

    executeOnClickAction(): void {
        const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
            return {
                name: templateArgument.name,
                value: templateArgument.value,
                calculatedValue: templateArgument.calculatedValue,
                subArguments: templateArgument.subArguments,
            };
        });
        const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CLICK');
        if (!!actionLinks && actionLinks.length > 0) {
            this.executeAction.emit({
                trigger: this.part.id,
                actionLinks: actionLinks,
                arguments: templateActionArguments,
            });
        }
    }
}
