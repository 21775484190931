import { createAction, props } from '@ngrx/store';
import { ApplicationDto } from '../../dto/application.dto.interface';

export const requestApplicationInformation = createAction(
    '[EFFECT] request application information',
    props<{ host: string; redirectToHome?: boolean }>()
);
export const requestApplicationInformationSuccess = createAction(
    '[EFFECT] request application information success',
    props<{ application: ApplicationDto; host: string; redirectToHome: boolean }>()
);
export const requestApplicationInformationError = createAction('[EFFECT] request application information error');

export const requestInitAuthentication = createAction('[ACTION] request init authentication', props<{ application: ApplicationDto }>());
export const requestInitAuthenticationSuccess = createAction(
    '[EFFECT] request init authentication success',
    props<{ application: ApplicationDto }>()
);
export const requestInitAuthenticationError = createAction('[EFFECT] request init authentication error');
