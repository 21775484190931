import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { PieChartPartDetail } from '../../model/pie-chart-part.detail';
import { PieChartPartStyle } from '../../model/pie-chart-part.style';
import { ChartTitleOptions } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/dto/chart-title-options.interface';
import {
    PieChartDataItemStyleOptions,
    PieChartDataOptions,
    PieChartSeriesOptions,
} from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/dto/pie-chart-series-options.interface';
import { ChartLegendOptions } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/dto/chart-legend-options.interface';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

@Component({
    selector: 'app-pie-chart-part-front',
    templateUrl: './pie-chart-part-front.component.html',
    styleUrls: ['./pie-chart-part-front.component.scss'],
})
export class PieChartPartFrontComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: PieChartPartDetail;
    partStyle: PieChartPartStyle;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    get part(): Part {
        return this._part;
    }

    set part(value: Part) {
        this._part = value;
        this._handleDetailChanged();
    }

    _part: Part;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    options: any;

    parentFormGroup: FormGroup;

    chartInstance: any;

    constructor(public changeDetectorRef: ChangeDetectorRef) {}

    public ngOnInit(): void {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.chartInstance) {
            this.chartInstance.resize();
        }
    }

    onChartInit(e: any): void {
        this.chartInstance = e;
        this.chartInstance.resize();
    }

    private _handleDetailChanged(): void {
        if (!!this._part) {
            const partDetail = this._part.detail as PieChartPartDetail;
            const partStyle = this._part.style as PieChartPartStyle;

            const titleOptions: ChartTitleOptions = {
                text: partDetail?.title,
                show: true,
            };

            const mapping =
                partDetail?.slices?.map(sourceDetail => {
                    const sourceStyle = partStyle?.series.find(x => x.id === sourceDetail.id);
                    return {
                        detail: sourceDetail,
                        style: sourceStyle,
                    };
                }) ?? [];

            const series = [
                new PieChartSeriesOptions({
                    data: mapping.map(
                        ref =>
                            new PieChartDataOptions({
                                value: ref.detail.value,
                                name: ref.detail.name,
                                itemStyle: new PieChartDataItemStyleOptions({ color: ref.style?.itemStyle?.color }),
                            })
                    ),
                    center: partStyle.centerX && partStyle.centerY ? [partStyle.centerX, partStyle.centerY] : ['50%', '50%'],
                    radius: partStyle.radiusOuter && partStyle.radiusInner ? [partStyle.radiusOuter, partStyle.radiusInner] : ['70%', '0%'],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                }),
            ];

            const { legend } = partStyle;
            const legendOptions = new ChartLegendOptions({
                show: legend?.show,
                orient: legend?.orient,
                left: legend?.left,
                top: legend?.top,
                data: mapping.map(source => ({ name: source.detail.name })),
                align: 'right',
            });

            this.options = {
                ...this.options,
                title: titleOptions,
                legend: legendOptions,
                tooltip: {
                    trigger: 'item',
                },
                series,
            };
        }
    }
}
