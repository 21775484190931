export class ChartRadarOptions {
    id?: string;
    indicator: ChartRadarIndicatorOptions[] = [];
    radius: string;
    shape: string;
    center: any[];

    constructor(base?: Partial<ChartRadarOptions>) {
        if (!!base) {
            this.id = base.id ?? this.id;
            this.indicator = base.indicator ?? this.indicator;
            this.radius = base.radius ?? this.radius;
            this.shape = base.shape ?? this.shape;
            this.center = base.center ?? this.center;
        }
    }
}

export class ChartRadarIndicatorOptions {
    name: string;
    max?: number;
    min?: number;

    constructor(base?: Partial<ChartRadarIndicatorOptions>) {
        if (!!base) {
            this.name = base.name ?? this.name;
            this.max = base.max ?? this.max;
            this.min = base.min ?? this.min;
        }
    }
}
