import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextFieldPartFrontComponent } from './components/text-field-part-front/text-field-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [TextFieldPartFrontComponent],
    imports: [CommonModule, NgxFloatUiModule, MatIconModule, MatInputModule, FormsModule, ReactiveFormsModule],
})
export class TextFieldPartModule {
    constructor() {
        getPartDetailComponents().set('text-field', TextFieldPartFrontComponent);
    }
}
